import { useLocation, useNavigate } from 'react-router-dom';

import { useFeatures } from 'lib';
import {
  ContactsIcon, ListIcon, MenuItem, PriceTagIcon, useLabels,
} from 'ui';
import { Divider } from '@mui/material';

import { paths } from './MenuItems';

const menuIconSize = 18;
const subMenuIconSize = 16;

const ContactsMenuItem = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  return (
    <MenuItem
      icon={<ContactsIcon height={menuIconSize} width={menuIconSize} />}
      label={l['menu-contacts']}
      onClick={() => handleNavigate(paths.contacts)}
      isActive={location.pathname.startsWith(paths.contacts)}
    />
  );
};

const PropertiesMenuItem = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const location = useLocation();

  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  return (
    <MenuItem
      icon={<ListIcon height={subMenuIconSize} width={subMenuIconSize} />}
      label={l['menu-properties']}
      onClick={() => handleNavigate(paths.properties)}
      isActive={location.pathname === paths.properties}
    />
  );
};

const LeadsMenuItem = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (path: string) => {
    onNavigate?.();
    navigate(path);
  };

  return (
    <MenuItem
      isActive={location.pathname === paths.leads}
      label={l.leads}
      onClick={() => handleNavigate(paths.leads)}
      icon={<PriceTagIcon size={menuIconSize} />}
    />
  );
};

export const PMDMenuItems = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const dividerCondition = features.isPmAppEnabled || features.isPmLeadsViewEnabled || features.isPmPropertiesDashboardEnabled;

  return !isLoadingFeatures && (
    <>
      {dividerCondition && <Divider />}
      {features.isPmAppEnabled && <ContactsMenuItem onNavigate={onNavigate} />}
      {features.isPmLeadsViewEnabled && <LeadsMenuItem onNavigate={onNavigate} />}
      {features.isPmPropertiesDashboardEnabled && <PropertiesMenuItem onNavigate={onNavigate} />}
    </>
  );
};
